import Structure from '../modules/structure';
import Swiper from 'swiper/bundle';

$.fn.mainSliderScript = function() {

    var jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('mainSliderScript', container, {
            "backgroundSlider": ".background-image-slider",
            "foregroundSlider": ".data-slider",
            "nav": ".controlls .counter .inner-counter",
            "nextSlide": ".controlls .next",
            "prevSlide": ".controlls .prev"
        }).structure;

        // init Swiper:
        const backgroundSwiper = new Swiper(structure.backgroundSlider.get(0), {
            slidesPerView: 1
        });

        const foreGroundSwiper = new Swiper(structure.foregroundSlider.get(0), {
            slidesPerView: 1,
            effect: "fade",
            navigation: {
                nextEl: structure.nextSlide.get(0),
                prevEl: structure.prevSlide.get(0),
            },
            pagination: {
                el: structure.nav.get(0),
                type: "fraction",
            },
            controller: {
                by: 'slide',
                control: backgroundSwiper
            }
        });
    });

    return this;
};

document.addEventListener('DOMContentLoaded', function() {
    $('.main-slider-swiper-js').mainSliderScript();
});

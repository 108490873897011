import Structure from '../modules/structure';
import Swiper from 'swiper/bundle';

$.fn.newsSliderScript = function() {

    const jqAllAffectedContainers = this;

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('newsSliderScript', container, {
            "nextSlide": ".controlls .slider-controlls .next",
            "prevSlide": ".controlls .slider-controlls .prev",
            "slider": ".news-slider-intermediate",
        }).structure;

        const swiperObj = new Swiper(structure.slider.get(0), {
            slidesPerView: 4,
            spaceBetween: 25, 
            navigation: {
                nextEl: structure.nextSlide.get(0),
                prevEl: structure.prevSlide.get(0),
            },
            breakpoints: {
                1224: {
                    slidesPerView: 4,
                    spaceBetween: 25,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 20, 
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10, 
                }
            }
        });

    });

    return this;
}

document.addEventListener('DOMContentLoaded', function() {
    $('.news-slider-js').newsSliderScript();
});
import Structure from '../modules/structure';
import Swiper from 'swiper/bundle';

$.fn.projectSliderScript = function() {

    const jqAllAffectedContainers = this;

    let swiperMainObj = null;

    const makeSlider = function(structure) {
        const swiperObj = new Swiper(structure.slider.get(0), {
            slidesPerView: 2,
            spaceBetween: 24, 
            navigation: {
                nextEl: structure.nextSlide.get(0),
                prevEl: structure.prevSlide.get(0),
            },
            pagination: {
                el: structure.nav.get(0),
                clickable: true
            },
            breakpoints: {
                1224: {
                    spaceBetween: 24, 
                },
                767: {
                    spaceBetween: 20, 
                },
                320: {
                    spaceBetween: 0,
                    slidesPerView: 1
                }
            }
        });

        return swiperObj;
    }

    jqAllAffectedContainers.each(function() {
        const container = $(this);
        const structure = new Structure('projectSliderScript', container, {
            "filters": {
                many: true,
                selector: ".filter .filter-holder .filter-item>a"
            },
            "nextSlide": ".projects-slider .next",
            "prevSlide": ".projects-slider .prev",
            "nav": ".projects-slider .nav",
            "slider": ".projects-slider .projects-slider-intermediate",
            "slides": {
                many: true,
                selector: ".projects-slider .projects-slider-intermediate .project-item"
            }
        }).structure;

        swiperMainObj = makeSlider(structure);

        structure.filters.each(function() {
            const filter = $(this);
            let filterName = filter.attr('href');
            filterName = filterName.substring(1);
            if (!filterName) {
                console.error('projectSliderScript no valid string for filter in href', this);
                return;
            }
            filter.on('click', function(e) {
                e.preventDefault();
                structure.filters.removeClass('current');
                filter.addClass('current');
                structure.slides.each(function() {
                    const slide = $(this);
                    if (!slide.hasClass(filterName)) {
                        slide.removeClass("swiper-slide").addClass('hidden');
                    }
                    if (slide.hasClass(filterName)) {
                        slide.removeClass('hidden').addClass("swiper-slide");
                    }
                    swiperMainObj.destroy();
                    swiperMainObj = makeSlider(structure);
                });
            });
        });
    });

    return this;
}

document.addEventListener('DOMContentLoaded', function() {
    $('.project-slider-js').projectSliderScript();
});